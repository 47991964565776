import { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar } from "../shared/nav-bar-component";
import { RootState } from "../../utils/types";
import {
  ListContainer,
  ResultsAnnouncement,
  ResultsTable,
  TableCell,
  TableHeader,
  TableRow,
  ChallengeContainer,
  ChallengeName,
  DropdownIcon,
} from "./index.styles";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";

const ResultsPageComponent = () => {
  const results = useSelector((state: RootState) => state.session.results);
  const [openChallenge, setOpenChallenge] = useState(null);

  const toggleChallenge = (challenge: any) => {
    if (openChallenge === challenge) {
      setOpenChallenge(null);
    } else {
      setOpenChallenge(challenge);
    }
  };

  return (
    <>
      <Navbar showbutton={true} title={"Results"} hamburger={false} />
      <MainContainer>
        <ListContainer>
          <ResultsAnnouncement>
            Results are now out! All categories posted below, scores are the
            average from all submissions.
          </ResultsAnnouncement>
          {results.map((challengeResult: any, idx: any) => (
            <ChallengeContainer
              key={idx}
              onClick={() => toggleChallenge(challengeResult.challenge)}
            >
              <ChallengeName>
                {challengeResult.challenge}
                <DropdownIcon
                  $isopen={openChallenge === challengeResult.challenge}
                >
                  ▼
                </DropdownIcon>
              </ChallengeName>

              {openChallenge === challengeResult.challenge && (
                <ResultsTable>
                  <thead>
                    <TableRow>
                      <TableHeader>Team</TableHeader>
                      <TableHeader>Score</TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {challengeResult.results
                      .slice(0, 10)
                      .map((proj: any, projIdx: any) => (
                        <TableRow key={projIdx}>
                          <TableCell>
                            {projIdx === 0 && "🥇  "}
                            {projIdx === 1 && "🥈  "}
                            {projIdx === 2 && "🥉  "}
                            {projIdx > 2 && `${projIdx + 1}. `}
                            {proj.proj_name}
                          </TableCell>
                          <TableCell>
                            {parseFloat(proj.proj_mark).toFixed(1)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </tbody>
                </ResultsTable>
              )}
            </ChallengeContainer>
          ))}
        </ListContainer>
      </MainContainer>
    </>
  );
};

export default ResultsPageComponent;
