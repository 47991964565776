import styled from "styled-components";

type ChallengerContainerProps = {
  $backgroundcolor?: string;
};

type DropdownProps = {
  $isopen?: boolean;
};

const ChallengeContainer = styled.div<ChallengerContainerProps>`
  margin: 20px;
  padding: 20px;
  border: 0px solid #ccc;
  border-radius: 10px;
  background-color: ${(props) =>
    props.$backgroundcolor ? props.$backgroundcolor : "#E1EBFF"};

  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

const ResultsAnnouncement = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #007d7d;
  text-align: left;
  margin: 20px auto;
  padding: 10px;
  margin-left: 10px;
  max-width: 700px;
`;

const ChallengeName = styled.div`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const ResultsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

const TableRow = styled.tr``;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const TableCell = styled.td`
  padding: 10px;
`;

const ListContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`;

const DropdownIcon = styled.span<DropdownProps>`
  float: right;
  transform: rotate(${(props) => (props.$isopen ? "180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
`;

export {
  DropdownIcon,
  ListContainer,
  TableCell,
  TableHeader,
  TableRow,
  ResultsTable,
  ChallengeContainer,
  ChallengeName,
  ResultsAnnouncement,
};
