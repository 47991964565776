import React from "react";
import { Navbar } from "../shared/nav-bar-component";
import { Project } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RootState } from "../../utils/types";
import ModalDialog from "../shared/modal-dialog-component";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectScore } from "../../reducers/project";
import { markProj } from "../../utils/api";
import ErrorComponent from "../shared/error-screen-component";
import { NavigateButton } from "../shared/buttons/index.styles";
import {
  ErrorMessage,
  CenterContainer,
  ProjectName,
  ProjectMembers,
  AverageScore,
  ScoreContainer,
  ScoreDisplay,
  ScoreFractionContainer,
  ProjectScoringContainer,
  CategoryContainer,
  CategoryDescription,
  CategoryName,
  Slider,
  SliderContainer,
  ScoreLabel,
  TotalScore,
  CommentsContainer,
  CommentsTextbox,
  NotesButton,
  SubmitButton,
  NoteImage,
} from "./index.styles";

import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";
import { Tooltip } from "../../components/shared/tooltip";
import Inter from "../../components/shared/fonts/inter";
import theme from "../../utils/theme";
import {
  PillContainer,
  PillRowContainer,
  WrapperContainer,
} from "../../components/shared/containers";
import NotesModal from "../../components/shared/modal-dialog-component/notes-modal";
import { TimeUpBanner } from "../../components/shared/banner";

type ProjectScoringProps = {
  project: Project;
};

const ProjectScoringComponent: React.FC<ProjectScoringProps> = ({
  project,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scoringCategories = useSelector(
    (state: RootState) => state.session.questionPrompts,
  );

  const [scores, setScores] = useState<number[]>(
    new Array(scoringCategories.length).fill(0),
  );
  const [notes, setNotes] = useState<string>(project.notes ?? "");
  const authToken = useSelector((state: RootState) => state.session.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalScore, setModalScore] = useState("");
  const [confirmAction, setConfirmAction] = useState<() => void>(() => {});
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const [dataError, setDataError] = useState("");
  const [favourite, setFavourite] = useState(false);

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const handleNotesClick = () => {
    setIsNotesModalOpen(true);
  };

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  React.useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsTimeUp(true);
      setIsBannerVisible(true);
    }
  }, [timeLeft]);
  const handleBannerClose = () => {
    setIsBannerVisible(false);
  };

  const handleBackClick = () => {
    setModalMessage(
      "Are you sure you wish to quit judging this project? The scores will not be saved.",
    );
    setModalHeader("Quit Judging?");
    setModalScore("");
    setConfirmAction(() => () => navigate(projectUrl));
    setIsModalOpen(true);
  };

  const handleFinalizeClick = async () => {
    setModalMessage(
      "Once you finalize your score, changes cannot be reverted. Do you wish to proceed?",
    );
    setModalHeader("Submit Project Score");
    setModalScore(
      `Overall Score: ${(
        scores.reduce((a, b) => a + b, 0) /
        scores.length /
        10
      ).toFixed(1)}`,
    );
    setConfirmAction(() => async () => {
      setIsFinalizing(true);
      const averageScore = parseFloat(
        (scores.reduce((a, b) => a + b, 0) / scores.length / 10.0).toFixed(1),
      );
      const trimmedNotes = notes ? notes : notes.trim();
      try {
        const response = await markProj(
          authToken,
          project.id,
          averageScore,
          trimmedNotes,
        );
        if (response.errorMessage) {
          setDataError(response.errorMessage);
        } else {
          dispatch(
            updateProjectScore({ projectId: project.id, score: averageScore }),
          );
          const successUrl = `/submissions/project/${project.id}/scoring/success`;
          navigate(successUrl);
        }
      } catch (error: any) {
        setNetworkError(error.message);
      } finally {
        setIsFinalizing(false);
      }
    });
    setIsModalOpen(true);
  };

  const projectUrl = `/submissions/project/${project.id}`;

  const handleScoreChange = (index: number, newScore: number) => {
    const updatedScores = [...scores];
    updatedScores[index] = newScore;
    setScores(updatedScores);
  };

  const handleNotesChange = (value: string) => {
    setNotes(cleanUpNotes(value));
  };

  const cleanUpNotes = (value: string) => {
    // removes any html tags
    let sanitizedValue = value.replace(/<[^>]*>/g, "");

    return sanitizedValue;
  };

  if (networkError) {
    return <ErrorComponent message={networkError} />;
  }

  const pillBlackCheckIcon = "/assets/icons/blackCheckMark.svg";
  const notesIcon = "/assets/icons/notes.svg";
  const pillClockIcon = "/assets/icons/clock.svg";
  const pillRedClockIcon = "/assets/icons/redClock.svg";

  const InterStyle = {
    fontSize: "13px",
    color: theme.white,
  };

  const ScoreInterStyle = {
    ...InterStyle,
    color: theme.black,
  };
  const onFavouriteToggle = () => {
    setFavourite(!favourite);
  };

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const timePillContainer = isTimeUp
    ? {
        border: `2px solid ${theme.warningRed}`,
        backgroundColor: theme.warningRedSecondary,
      }
    : {
        border: "2px solid #8C9AEA",
      };

  const timePillTextStyle = {
    ...ScoreInterStyle,
    color: isTimeUp ? theme.warningRed : theme.black,
  };

  return (
    <>
      <Navbar
        showbutton={true}
        popStack={projectUrl}
        title="Project Scoring"
        hamburger={false}
        favourite={favourite}
        onFavouriteClick={onFavouriteToggle}
        onBackClick={handleBackClick}
      />
      <MainContainer>
        <WrapperContainer>
          <PillRowContainer>
            <PillContainer
              icon={<img src={pillBlackCheckIcon} />}
              text={
                <Inter style={ScoreInterStyle}>
                  SCORE:{" "}
                  {(
                    scores.reduce((a, b) => a + b, 0) /
                    scores.length /
                    10
                  ).toFixed(1)}
                </Inter>
              }
              backgroundColor={theme.secondaryOrange}
            />
            <PillContainer
              icon={<img src={isTimeUp ? pillRedClockIcon : pillClockIcon} />}
              text={
                <Inter style={timePillTextStyle}>
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </Inter>
              }
              style={timePillContainer}
              backgroundColor={isTimeUp ? theme.warningRedSecondary : "#F4CCFE"}
            />
          </PillRowContainer>

          {/*<CenterContainer>*/}
          {/*  <ProjectName>{project.name}</ProjectName>*/}
          {/*  <ProjectMembers>*/}
          {/*    {project.members.map((member) => member.name).join(", ")}*/}
          {/*  </ProjectMembers>*/}
          {/*</CenterContainer>*/}
          <ProjectScoringContainer>
            {/*<CommentsContainer>*/}
            {/*  <CategoryName>Comments & Notes</CategoryName>*/}
            {/*  <CommentsTextbox*/}
            {/*    value={notes}*/}
            {/*    onChange={(e) => handleNotesChange(e.target.value)}*/}
            {/*    placeholder="Write your comments/notes here."*/}
            {/*  />*/}
            {/*</CommentsContainer>*/}
            {scoringCategories.map((category, index) => (
              <CategoryContainer key={index}>
                <CategoryName>
                  {category.categoryName}{" "}
                  <Tooltip text={category.categoryQuestion} />
                </CategoryName>

                <SliderContainer>
                  <Slider
                    type="range"
                    min="0"
                    max="100"
                    step="5"
                    value={scores[index]}
                    $isFilledCompletely={scores[index] === 100}
                    onChange={(e) =>
                      handleScoreChange(index, Number(e.target.value))
                    }
                  />
                  <ScoreDisplay>
                    {(scores[index] / 10.0).toFixed(1)}
                  </ScoreDisplay>
                </SliderContainer>
              </CategoryContainer>
            ))}
          </ProjectScoringContainer>
          <SubmitButton
            onClick={() => handleFinalizeClick()}
            disabled={isFinalizing}
          >
            {isFinalizing ? "Submitting..." : "Submit Score"}
          </SubmitButton>
          <NotesButton onClick={handleNotesClick} disabled={isFinalizing}>
            <NoteImage src={notesIcon} />
            Take Notes
          </NotesButton>
          <ErrorMessage>{dataError}</ErrorMessage>
        </WrapperContainer>
      </MainContainer>
      {isNotesModalOpen && (
        <NotesModal
          onClose={handleNotesModalClose}
          value={notes}
          onChange={handleNotesChange}
        />
      )}
      <ModalDialog
        $isopen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          setIsModalOpen(false);
          confirmAction();
        }}
        message={modalMessage}
        header={modalHeader}
        scorePrompt={modalScore}
      />
      <TimeUpBanner isOpen={isBannerVisible} onClose={handleBannerClose} />
    </>
  );
};

export default ProjectScoringComponent;
