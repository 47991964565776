import styled from "styled-components";
import { NavigateButton } from "../shared/buttons/index.styles";
import theme from "../../utils/theme";
import Inter from "../../components/shared/fonts/inter";

const ProjectInfoContainer = styled.div`
  max-width: 700px;
  width: 95%;
  margin: 0 auto;
`;

const ScorePrompt = styled.div`
  font-size: 18px;
  color: #333;
  margin-top: 20px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Banner = styled.div<{ $backgroundColor: string }>`
  background-color: ${(props) => props.$backgroundColor};
  background-size: cover;
  background-position: center;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ProjectName = styled.h1`
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
`;

const MembersList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
`;

const Member = styled.li`
  margin: 10px 0;
`;

const SectionHeader = styled(Inter)`
  color: ${theme.black};
  font-weight: normal;
  margin-top: 5px;
  font-size: 20px;
`;

const ExistingScorePrompt = styled(SectionHeader)`
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
  color: #f0a000;
`;

const DevPostButton = styled(NavigateButton)`
  background-color: ${theme.white};
  color: ${theme.darkBlue};
  width: 240px;
  border: 2px solid ${theme.darkBlue};

  font-size: 18px;
  cursor: pointer;
  margin: 0px;
`;

const LinkImage = styled.img`
  margin-left: 10px;
  width: 13px;
  height: 13px;
`;

const MemberName = styled.span`
  font-weight: normal;
`;

export {
  ProjectInfoContainer,
  ProjectName,
  ScorePrompt,
  ButtonContainer,
  Banner,
  DevPostButton,
  SectionHeader,
  ExistingScorePrompt,
  Member,
  MembersList,
  MemberName,
  LinkImage,
};
