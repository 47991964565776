import React, { useState, useEffect } from "react";
import {
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  DropdownIcon,
  DropdownOption,
} from "./index.styles";

type DropdownProps = {
  options: any[];
  onSelect: (selectedOption: any) => void;
};

const CustomDropdown: React.FC<DropdownProps> = ({ options, onSelect }) => {
  const [$isopen, set$isopen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select your name");

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (selectedOption === "My name is not listed") {
      setSelectedOption("I don’t see my name listed");
    }
  }, [selectedOption]);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        set$isopen(false);
      }
    };

    if ($isopen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [$isopen]);

  const toggleDropdown = () => set$isopen(!$isopen);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    onSelect(option);
    set$isopen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        onClick={toggleDropdown}
        disabled={selectedOption === "Select your name"}
      >
        {selectedOption}
        <DropdownIcon $isopen={$isopen}>▼</DropdownIcon>
      </DropdownButton>
      <DropdownContent $isopen={$isopen}>
        {options.map((option, index) => (
          <DropdownOption key={index} onClick={() => handleOptionClick(option)}>
            {option}
          </DropdownOption>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default CustomDropdown;
