import styled from "styled-components";
import theme from "../../../utils/theme";

type HamburgerProps = {
  $showhamburger?: boolean;
};

type BackButtonProps = {
  $showbutton?: boolean;
  $genericVariant?: boolean;
};

type NavBarContainerProps = {
  $genericVariant?: boolean;
};

type MobileMenuProps = {
  open?: boolean | null;
  $ismobile?: boolean | null;
};

const BackButton = styled.button<BackButtonProps>`
  ${(props) =>
    props.$showbutton === true || props.$showbutton === null
      ? ""
      : "display: none;"}
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 10px;
  ${(props) => (props.$genericVariant ? "top: 75%;" : "top: 50%;")}
  transform: translateY(-50%);
`;

const NavbarContainer = styled.div<NavBarContainerProps>`
  position: fixed;
  padding: 10px 5px;
  padding-bottom: 0px;
  ${(props) => (props.$genericVariant ? "padding-top: 50px;" : undefined)}
  min-height: 50px;

  width: 100%;
  //color: #0a165a;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 998;

  > div:nth-child(2) {
    text-align: center;
    width: 100vw;
  }
`;

const NavLink = styled.a`
  color: #0a165a;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: grey;
  }
`;

const NavLinkItem = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

const HamburgerMenu = styled.div<HamburgerProps>`
  ${(props) =>
    props.$showhamburger === true || props.$showhamburger === null
      ? ""
      : "display: None;"}
  cursor: pointer;
  font-size: 20px;
  z-index: 1002;
  position: absolute;
  right: 20px;
  top: 45%;
  transform: translateY(-50%);
`;

const MobileMenu = styled.div<MobileMenuProps>`
  transform: translateY(${(props) => (props.open ? "0" : "-200%")});
  transition: transform 0.3s ease-in-out;

  position: fixed;
  background-color: #f0f0f0;
  top: ${(props) => (props.$ismobile ? "75px" : "110px")};
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 999;
`;

const MobileNavLinkItem = styled.li`
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 30px;
  width: 100%;
  padding: 10px 0;
  list-style-type: none;
`;

const MainContainer = styled.div`
  padding-top: 130px;
  max-width: 700px;
  margin: 0 auto;
  background-color: ${theme.lightTextGray};
  height: 100%;
  width: 100%;
`;

const BackgroundContainer = styled.div`
  background-color: ${theme.lightTextGray};
  min-height: 100vh;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export {
  MobileNavLinkItem,
  MobileMenu,
  MainContainer,
  HamburgerMenu,
  NavLink,
  NavLinkItem,
  NavbarContainer,
  BackButton,
  BackgroundContainer,
};
