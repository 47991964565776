import styled from "styled-components";
import theme from "../../../utils/theme";

const BackgroundWrapper = styled.div<{ isLanding?: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  background-color: ${(props) =>
    props.isLanding ? theme.lavenderBlue : theme.white};
  z-index: -1;
`;

const SvgIcon = styled.img<{ isMobile?: boolean }>`
  position: absolute;
  width: ${(props) => (props.isMobile ? "200px" : "300px")};
  height: ${(props) => (props.isMobile ? "200px" : "300px")};
  overflow: hidden;
  z-index: 0;
`;

const TopRightIcon = styled(SvgIcon)<{ isMobile?: boolean | null }>`
  position: absolute;
  top: ${(props) => (props.isMobile ? "-80px" : "-120px")};
  right: ${(props) => (props.isMobile ? "-70px" : "-120px")};
  z-index: 1;
`;

const TopLeftIcon = styled(SvgIcon)<{ isMobile?: boolean | null }>`
  position: absolute;
  transform: scale(0.4);
  top: ${(props) => (props.isMobile ? "-40px" : "-100px")};
  left: ${(props) => (props.isMobile ? "-20px" : "-70px")};
  z-index: 1;
`;

const BottomLeftIcon = styled(SvgIcon)<{ isMobile?: boolean | null }>`
  position: absolute;
  bottom: ${(props) => (props.isMobile ? "150px" : "120px")};
  left: ${(props) => (props.isMobile ? "-63px" : "-160px")};
  z-index: 1;
`;

export {
  BackgroundWrapper,
  SvgIcon,
  TopLeftIcon,
  TopRightIcon,
  BottomLeftIcon,
};
