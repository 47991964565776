import { RootState } from "@/utils/types";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
interface ProtectedRouteProps {
  children: ReactNode;
  isPermitted?: boolean;
  redirect: string;
  results?: boolean;
  judging?: boolean;
  judgingClosed?: boolean;
  pinCheck?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  results = false,
  judging = false,
  judgingClosed = false,
  pinCheck = false,
  isPermitted = true,
  redirect,
}) => {
  const judgingOpen = useSelector(
    (state: RootState) => state.session.judgingOpen
  );
  const resultsOpen = useSelector(
    (state: RootState) => state.session.resultsOpen
  );

  const currentPin = useSelector(
    (state: RootState) => state.session.challengeId
  );
  if (
    !isPermitted ||
    (judging && !judgingOpen) ||
    (results && !resultsOpen) ||
    (judgingClosed && judgingOpen) ||
    (pinCheck && currentPin.length === 0)
  ) {
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
