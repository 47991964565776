import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetProjects } from '../../reducers/project';
import { resetSession } from '../../reducers/session'
import LoadingComponent from '../shared/loading-screen-component';
type LogOutProps = {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};


const Logout: React.FC<LogOutProps> = ({setIsAuthenticated}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsAuthenticated(false);
    dispatch(resetProjects());
    dispatch(resetSession());

    localStorage.removeItem('company_pin');
    localStorage.removeItem('user_name');
    
    navigate('/');
  }, []);

  return (
    <LoadingComponent/>
  );
};

export default Logout;