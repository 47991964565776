import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContainer, ErrorMessage, BackButton } from "./index.styles";

const ErrorComponent: React.FC<{ message: string }> = ({ message }) => {
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <ErrorMessage>Error: {message}</ErrorMessage>
      <BackButton onClick={() => navigate("/logout")}>Back to Main Menu</BackButton>
    </ErrorContainer>
  );
};

export default ErrorComponent;
