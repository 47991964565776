import { Navbar } from "../shared/nav-bar-component";
import Inter from "../shared/fonts/inter";
import { useNavigate } from "react-router-dom";
import { NotFoundContainer, StyledButton } from "./index.styles";

const SchedulePageComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar showbutton={true} title={"Schedule"} hamburger={false} />
      <NotFoundContainer>
        <Inter>This page is under-construction.</Inter>
        <StyledButton onClick={() => navigate("/home")}>
          Return Home
        </StyledButton>
      </NotFoundContainer>
    </>
  );
};

export default SchedulePageComponent;
