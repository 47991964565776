import styled from "styled-components";

type CardButtonProps = {
  disabled?: boolean;
  $showlinkicon?: boolean;
  $backgroundcolor?: string;
};

const CardButton = styled.div<CardButtonProps>`
  background-color: ${(props) => props.$backgroundcolor || "white"};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 600px;
  width: 80%;
  height: 50px;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  &::after {
    content: ${(props) => (props.$showlinkicon ? '""' : "none")};
    display: ${(props) => (props.$showlinkicon ? "inline-block" : "none")};
    width: 20px; 
    height: 20px;
    background-image: url("/assets/icons/link.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
  }

  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
export default CardButton;
