import styled from "styled-components";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";

type ProjectItemProps = {
  $backgroundcolor?: string;
};

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
  flex-wrap: nowrap;
`;

const ScrollProtection = styled.div`
  overscroll-behavior: none;
`;
const EditButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 10px;

  &:hover {
    background-color: #0056b3;
  }
`;
const Divider = styled.hr`
  border: 1px solid #e0e0e0;
  margin: 0px 10px 30px 10px;
`;

const ListContainer = styled.div`
  max-width: 700px;
  padding-top: 20px;
  width: 95%;
  margin: 0 auto;
  overflow-x: hidden;
`;

const ProjectItem = styled.div<ProjectItemProps>`
  background-color: ${(props) =>
    props.$backgroundcolor ? props.$backgroundcolor : "#E1EBFF"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px;
  padding: 5px 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

const SearchInput = styled.input`
  margin-top: 20px;
  padding: 10px 35px 10px 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  padding-right: 10px;
  width: 90%;
  @media (min-width: 675px) {
    width: 95%;
  }
  @media (max-width: 281px) {
    width: 85%;
  }

  background-color: #f0f0f0;
  font-size: 16px;
  background-image: url("/assets/icons/search.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 20px;
`;

const TitleElement = styled.div`
  flex: 2;
  text-align: left;
  min-width: 0;
`;

const ScoreElement = styled(TitleElement)`
  flex: 1;
  text-align: center;
`;

const ActionsElement = styled(TitleElement)`
  flex: 1;
  text-align: right;
`;

export {
  ActionsElement,
  TitleElement,
  ScoreElement,
  SearchInput,
  ProjectItem,
  ListContainer,
  Divider,
  EditButton,
  HeaderTitle,
  ScrollProtection,
};
