import styled from "styled-components";
type PromptContainerProps = {
  $issuccess: boolean;
};

const PromptContainer = styled.div<PromptContainerProps>`
  ${(props) =>
    props.$issuccess
      ? `background-color: #8BBE79;`
      : `background-color: #BE8B8F;`}
  height: 100vh;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PromptMessage = styled.h2`
  color: white;
  font-size: 24px;
  text-align: center;
  max-width: 300px;
`;

const NavigateButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  width: 250px;
  height: 60px;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;

  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { PromptContainer, PromptMessage, ButtonContainer, NavigateButton };
