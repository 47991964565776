import theme from "../../../utils/theme";
import styled from "styled-components";
import { keyframes } from "styled-components";

const DropdownContainer = styled.div`
  border-radius: 4px;
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  background-color: ${(props) =>
    props.disabled ? theme.lightTextGray : theme.white};
  color: ${(props) => (props.disabled ? theme.disabledGray : theme.black)};
  border: 1px solid var(--primary-200, #8c9aea);
  min-height: 35px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => (props.disabled ? "bold" : "normal")};
`;

const DropdownIcon = styled.span<{ $isopen: boolean }>`
  margin-bottom: 2.5px;
  transform: ${({ $isopen }) => ($isopen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-10px);
        visibility: hidden;
    }
    to {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
`;

const DropdownContent = styled.div<{ $isopen: boolean }>`
  border-radius: 4px;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  z-index: 1;
  background-color: ${theme.white};
  border: 1px solid var(--neutral-400, #bfbcbb);
  max-height: 175px;
  overflow-y: auto;
  scrollbar-width: thin;
  animation: ${({ $isopen }) => ($isopen ? fadeIn : undefined)} 0.3s ease-in-out;
  display: ${({ $isopen }) => ($isopen ? "block" : "none")};
`;

const DropdownOption = styled.div`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  padding: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid #ccc;
  }

  &:last-child {
    font-weight: bold;
  }
`;

export {
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  DropdownOption,
  DropdownIcon,
};
