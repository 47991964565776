import React from "react";
import styled from "styled-components";
import theme from "../../../../utils/theme";

const ModalBackground = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;

  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 700px;
  height: 60%;
  background-color: ${theme.white};
  border-radius: 16px 16px 0 0;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${theme.black};
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  margin-top: 40px;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  color: ${theme.black};
  background-color: ${theme.white};
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;

  &::placeholder {
    color: ${theme.gray};
  }
`;

const NotesModal: React.FC<{
  onClose: () => void;
  value: string;
  onChange: (value: string) => void;
}> = ({ onClose, value, onChange }) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <ModalContainer ref={modalRef}>
        <CloseButton onClick={onClose}>✕</CloseButton>
        <Textarea
          placeholder="Type your notes here. They will be saved automatically."
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </ModalContainer>
    </ModalBackground>
  );
};

export default NotesModal;
