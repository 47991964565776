import styled from "styled-components";

type BackIconProps = {
  onClick?: () => void;
};

const BackIconComponent = styled.div<BackIconProps>`
  justify-content: start;
  width: 1rem;
  height: 1rem;
`;

export { BackIconComponent };
