import React, { useState, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import ErrorComponent from "../../../shared/error-screen-component";
import LoadingComponent from "../../../shared/loading-screen-component";
import { fetchJudgeList } from "../../../../utils/api";
import {
  setBackdropImage, setChallengeName,
  setQuestionPrompts, setUsersList,
} from "../../../../reducers/session";

import {
  cohereCriteria,
  rbcCriteria,
  cseCriteria,
  digileCriteria,
  defaultCriteria,
  cohereBackdropImage,
  defaultBackdropImage,
  rbcBackdropImage,
} from "../../../../utils/constants";

interface LoadChallengeInfoDataProps {
  children: ReactNode;
}
const LoadChallengeInfo: React.FC<LoadChallengeInfoDataProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const company_pin = useSelector((state: RootState) => state.session.challengeId);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const judgeList = await fetchJudgeList(company_pin);
        const companyName = judgeList.challenge;
        const judgesFromChallenge = judgeList.judges;
        if (companyName?.toLowerCase().includes("co:here")) {
          dispatch(setQuestionPrompts(cohereCriteria));
          dispatch(setBackdropImage(cohereBackdropImage));
        } else if (companyName?.toLowerCase().includes("rbc")) {
          dispatch(setQuestionPrompts(rbcCriteria));
          dispatch(setBackdropImage(rbcBackdropImage));
        } else if (companyName?.toLowerCase().includes("cse")) {
          dispatch(setQuestionPrompts(cseCriteria));
          dispatch(setBackdropImage(defaultBackdropImage));
        } else if (companyName?.toLowerCase()?.includes("digile")) {
          dispatch(setQuestionPrompts(digileCriteria));
          dispatch(setBackdropImage(defaultBackdropImage));
        }
        else {
          dispatch(setQuestionPrompts(defaultCriteria));
          dispatch(setBackdropImage(defaultBackdropImage));
        }
        dispatch(setChallengeName(companyName));
        dispatch(setUsersList(judgesFromChallenge ? judgesFromChallenge : []));
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent message={error} />;

  return <>{children}</>;
};

export default LoadChallengeInfo;
