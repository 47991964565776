import styled from "styled-components";

const Prompt = styled.p`
  color: black;
  max-width: 640px;
  width: 80%;
  text-align: left;
`;

export { Prompt };
