// user.tsx
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryScore, Session } from "@/utils/types";
import { defaultCriteria } from "../utils/constants";

// const initialState: User = {
//   id: 0,
//   companyName: "",
//   challengeName: "",
//   username: "",
// };

// Presentation Notes:
// --------------------
// - dont use "token"
// - pause between each page (ask if they have any questions)
// - give 5 mins for people to login
// - stall a bit of time during the project scoring part of the presentation
// - results page for Rue
// - 5 seconds between each segment
// - score gets replaced
// - say that this is the first time we deployed this app (?)
// - don't click the results button when it is not ready

const initialState: Session = {
  id: "0",
  companyName: "",
  challengeName: "",
  challengeId: "",
  userName: "",
  questionPrompts: defaultCriteria,
  usersList: [],
  resultsOpen: false,
  results: [],
  judgingOpen: false,
  backdropImage: "/assets/challenge-backdrops/general.svg",
};

const sessionSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetSession: () => initialState,
    setUser: (state, action: PayloadAction<Session>) => {
      return action.payload;
    },
    setUsersList: (state, action: PayloadAction<string[]>) => {
      state.usersList = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setChallengeName: (state, action: PayloadAction<string>) => {
      state.challengeName = action.payload;
    },
    setChallengeId: (state, action: PayloadAction<string>) => {
      state.challengeId = action.payload;
    },
    setResultsOpenStatus: (state, action: PayloadAction<boolean>) => {
      state.resultsOpen = action.payload;
    },
    setResults: (state, action: PayloadAction<any[]>) => {
      state.results = action.payload;
    },
    setJudgingOpenStatus: (state, action: PayloadAction<boolean>) => {
      state.judgingOpen = action.payload;
    },
    setQuestionPrompts: (state, action: PayloadAction<CategoryScore[]>) => {
      state.questionPrompts = action.payload;
    },
    setBackdropImage: (state, action: PayloadAction<string>) => {
      state.backdropImage = action.payload;
    },
  },
});

export const {
  setUser,
  setUsersList,
  setId,
  setUserName,
  setChallengeId,
  setChallengeName,
  setResultsOpenStatus,
  setResults,
  setJudgingOpenStatus,
  resetSession,
  setQuestionPrompts,
  setBackdropImage,
} = sessionSlice.actions;
export default sessionSlice.reducer;
