import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { useMobileDetect } from "../../../../hooks/useMobileDetect";

type StyledInterTextProps = {
  style?: any;
  $ismobile?: boolean | null;
};

const InterSemiBoldFont = css<StyledInterTextProps>`
  @font-face {
    font-family: "InterSemiBold";

    src: url("/fonts/Inter-SemiBold.woff2") format("woff2");

    font-weight: normal;
    font-style: normal;
  }
`;

const StyledInterText = styled.p<StyledInterTextProps>`
  ${InterSemiBoldFont}
  font-family: InterSemiBold;
  text-align: center;
  font-size: ${(props) => (props.$ismobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--black, #282828);
`;

type InterProps = StyledInterTextProps & {
  children: ReactNode;
};

const InterSemiBold: React.FC<InterProps> = ({ children, style, ...props }) => {
  const $ismobile = useMobileDetect();
  return (
    <StyledInterText style={style} $ismobile={$ismobile} {...props}>
      {children}
    </StyledInterText>
  );
};

export default InterSemiBold;
