import UserSelectComponent from "../../components/user-select-component";

type UserSelectProps = {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const UserSelectPage: React.FC<UserSelectProps> = ({ setIsAuthenticated }) => {
  return <UserSelectComponent setIsAuthenticated={setIsAuthenticated} />;
};

export default UserSelectPage;
