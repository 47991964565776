import React, { useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import theme from "../../utils/theme";

const slideUp = keyframes`
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
`;

const slideDown = keyframes`
    0% {
        bottom: 0;
    }
    100% {
        bottom: -100%;
    }
`;

const NumPadContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  height: 35%;
  bottom: ${({ isVisible }) => (isVisible ? "0" : "-100%")};
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: white;
  padding: 20px 20px;
  margin: 0 auto;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 400px;
  overflow: hidden;
  transition: bottom 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${slideUp} 0.3s ease-in-out forwards;
        `
      : css`
          animation: ${slideDown} 0.3s ease-in-out forwards;
        `}
`;

const NumPadButton = styled.button`
  font-size: 15px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  color: ${theme.black};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

const EmptySpace = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 100%;
  background-color: white;
`;

interface NumPadProps {
  isVisible: boolean;
  onClick: (num: string) => void;
  onDelete: () => void;
}

export const NumPad: React.FC<NumPadProps> = ({
  isVisible,
  onClick,
  onDelete,
}) => {
  const numValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "",
    "0",
    "CLR",
  ];

  const clearIcon = "/assets/icons/padButtonDelete.svg";

  const isVisibleRef = useRef(isVisible);

  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!isVisibleRef.current) return;
    if (event.key >= "0" && event.key <= "9") {
      onClick(event.key);
    } else if (event.key === "Backspace" || event.key === "Delete") {
      onDelete();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <NumPadContainer isVisible={isVisible}>
      {numValues.map((num, idx) => {
        if (num === "") {
          return <EmptySpace key={`empty-${idx}`} />;
        }
        return (
          <NumPadButton
            key={num}
            onClick={() => (num === "CLR" ? onDelete() : onClick(num))}
          >
            {num === "CLR" ? <img src={clearIcon} alt="Clear Icon" /> : num}
          </NumPadButton>
        );
      })}
    </NumPadContainer>
  );
};

export default NumPad;
