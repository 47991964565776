import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  setUsersList,
  setChallengeId,
  setQuestionPrompts,
  setBackdropImage,
  setChallengeName,
} from "../../reducers/session";
import InfoText from "./info-text";
import theme from "../../utils/theme";
import PinBox from "./pin-box";
import { NavigateButton } from "../shared/buttons/index.styles";
import { useNavigate } from "react-router-dom";
import { fetchJudgeList } from "../../utils/api";
import { Navbar } from "../shared/nav-bar-component";
import BricolageGrotesque from "../../components/shared/fonts/bricolage-grotesque";
import { TitleContainer } from "../user-select-component/index.styles";
import Inter from "../../components/shared/fonts/inter";
import {
  PageContainer,
  ErrorMessage,
  StyledDropdownButton,
} from "./index.styles";
import {
  cohereCriteria,
  digileCriteria,
  rbcCriteria,
  cseCriteria,
  defaultCriteria,
  cohereBackdropImage,
  rbcBackdropImage,
  defaultBackdropImage,
} from "../../utils/constants";
import { NumPad } from "../../components/login-component/NumPad";
import GenericBackground from "../../components/shared/generic-background"; // Import NumPad component from NumPad.tsx

const StyledNavigateButton = styled(NavigateButton)`
  width: 100%;
  margin-top: 20px;
`;

export const LoginComponent = () => {
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNumPadVisible, setIsNumPadVisible] = useState(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const numPadRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleNumClick = (num: any) => {
    setPin((current) => (current.length < 5 ? current + num : current));
  };

  const handleDelete = () => {
    setPin((current) => current.slice(0, -1));
  };

  const handleLogin = async () => {
    setErrorMessage("");
    setIsLoading(true);
    const pinRegex = /^\d{5}$/;
    if (pin.length !== 5 || !pinRegex.test(pin)) {
      setErrorMessage("Please complete your PIN number");
      setIsLoading(false);
      return;
    }
    try {
      const data = await fetchJudgeList(pin);
      const challengeName = data.challenge;
      dispatch(setUsersList(data.judges));
      dispatch(setChallengeId(pin));
      dispatch(setChallengeName(challengeName));
      if (challengeName?.toLowerCase()?.includes("co:here")) {
        dispatch(setQuestionPrompts(cohereCriteria));
        dispatch(setBackdropImage(cohereBackdropImage));
      } else if (challengeName?.toLowerCase()?.includes("rbc")) {
        dispatch(setQuestionPrompts(rbcCriteria));
        dispatch(setBackdropImage(rbcBackdropImage));
      } else if (challengeName?.toLowerCase()?.includes("digile")) {
        dispatch(setQuestionPrompts(digileCriteria));
        dispatch(setBackdropImage(defaultBackdropImage));
      } else if (challengeName?.toLowerCase()?.includes("cse")) {
        dispatch(setQuestionPrompts(cseCriteria));
        dispatch(setBackdropImage(defaultBackdropImage));
      } else {
        dispatch(setQuestionPrompts(defaultCriteria));
        dispatch(setBackdropImage(defaultBackdropImage));
      }
      navigate("/user-select");
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        numPadRef.current &&
        !numPadRef.current.contains(event.target as Node)
      ) {
        setIsNumPadVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, numPadRef]);

  useEffect(() => {
    if (pin.length === 5) {
      handleLogin().then((r) => setPin(""));
    }
  }, [pin]);

  const titleFontSize = "30px";
  const titleMargin = "20px 0";
  return (
    <>
      <GenericBackground />
      <PageContainer>
        <TitleContainer style={{ width: "100%" }}>
          <BricolageGrotesque
            style={{
              fontSize: titleFontSize,
              letterSpacing: "0.0rem",
              color: theme.black,
              lineHeight: "1.1",
              whiteSpace: "pre-line",
              textAlign: "left",
              margin: titleMargin,
            }}
          >
            Enter your pin
          </BricolageGrotesque>
          <Inter
            style={{
              fontSize: "16px",
              textAlign: "left",
              marginBottom: "10px",
            }}
          >
            Please enter your 5-digit pin below.
          </Inter>
          <StyledDropdownButton
            isVisible={true}
            isError={Boolean(errorMessage)}
            onClick={() => setIsNumPadVisible(true)}
            ref={dropdownRef}
          >
            <PinBox value={pin} valueLength={5} />
          </StyledDropdownButton>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <InfoText />
        </TitleContainer>

        {/*<StyledNavigateButton*/}
        {/*  onClick={() => handleLogin()}*/}
        {/*  disabled={isLoading || pin.length !== 5}*/}
        {/*>*/}
        {/*  {isLoading ? "Entering..." : "Continue"}*/}
        {/*</StyledNavigateButton>*/}
      </PageContainer>
      <div ref={numPadRef}>
        <NumPad
          isVisible={true && !isLoading}
          onClick={handleNumClick}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default LoginComponent;
