import React, { useRef } from "react";
import { PinGroup, PinInput } from "./index.styles";

type Props = {
  value: string;
  valueLength: number;
};

const PinBox = ({ value, valueLength }: Props) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  return (
    <PinGroup>
      {Array.from({ length: valueLength }).map(
        (_, idx) =>
          idx < value.length && (
            <PinInput
              readOnly
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={"•"}
              ref={(el) => (inputRefs.current[idx] = el)}
            />
          ),
      )}
    </PinGroup>
  );
};

export default PinBox;
