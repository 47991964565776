import React from "react";
import styled from "styled-components";
import theme from "../../../utils/theme";
import Inter from "../../../components/shared/fonts/inter";

const BannerContainer = styled.div<{ $isopen: boolean }>`
  position: fixed;
  top: ${({ $isopen }) => ($isopen ? "130px" : "-120px")};
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-color: ${theme.warningRedSecondary};
  color: ${theme.warningRed};
  border: 2px solid ${theme.warningRed};
  display: flex;
  align-items: flex-start; /* Align items to the top */
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: top 0.3s ease-in-out;
  z-index: 1001;
`;

const CautionIcon = styled.img`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  margin-top: 4px; /* Slight adjustment to align better with the text */
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure the text stays at the top */
`;

const BannerCloseButton = styled.button`
  margin-left: auto;
  margin-top: 4px; /* Slight adjustment to align better with the content */
  background: none;
  border: none;
  color: ${theme.warningRed};
  font-size: 18px;
  cursor: pointer;
`;

export const TimeUpBanner: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const HeaderStyle = {
    fontSize: "17px",
    marginTop: "7px",
    fontWeight: "bold",
  };

  const SubtitleStyle = {
    fontSize: "14px",
    margin: "0px",
    fontWeight: "normal",
  };
  return (
    <BannerContainer $isopen={isOpen}>
      <CautionIcon src="/assets/icons/caution.svg" alt="Caution Icon" />
      <BannerContent>
        <Inter style={HeaderStyle}>Time is up!</Inter>
        <Inter style={SubtitleStyle}>
          Submit your scores as soon as possible
        </Inter>
      </BannerContent>
      <BannerCloseButton onClick={onClose}>✕</BannerCloseButton>
    </BannerContainer>
  );
};
