import styled from "styled-components";
import theme from "../../utils/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 60px;
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

const ButtonFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const InfoTextContainer = styled.div`
  width: 100%;
  color: var(--neutral-500, #7d7b7a);
  font-size: 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  padding: 5px;
`;

const BoldInfoText = styled.span`
  opacity: var(--sds-size-stroke-border);
  color: var(--neutral-600, #19191b);
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  max-width: 300px;
  max-height: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Dropdown = styled.select`
  padding: 10px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;

  option {
    padding: 10px;
  }

  option[value="Add new user"]:before {
    content: " ";
    display: block;
    border-top: 1px solid #000;
    margin: 10px 0;
  }
`;

const TextInput = styled.input`
  position: relative;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--neutral-400, #bfbcbb);
  background-color: ${theme.white};
  color: ${theme.darkBlue};
  font-size: 14px;
  font-family: "Inter";

  width: calc(100% - 22px);

  display: none;

  &.show {
    display: block;
  }

  &::placeholder {
    color: ${theme.darkBlue};
    font-family: "Inter";
  }
`;
const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AddUserButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AddUserButton = styled.button`
  background: none;
  color: ${theme.darkBlue};
  border: none;
  border-radius: 10px;
  height: 50px;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    color: var(--neutral-500, #7d7b7a);
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export {
  Container,
  TitleContainer,
  ButtonFooterContainer,
  Image,
  Dropdown,
  TextInput,
  ErrorMessage,
  AddUserButtonContainer,
  AddUserButton,
  InfoTextContainer,
  BoldInfoText,
};
