import { Navbar } from "../shared/nav-bar-component";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "@/utils/types";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";

const ProjectScoringContainer = styled.div`
  max-width: 700px;
  width: 95%;
  margin: 60px auto;
`;

const CategoryContainer = styled.div`
  background-color: #e1ebff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const CategoryName = styled.label`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CategoryDescription = styled.p`
  margin-bottom: 10px;
`;

const JudgingCriteriaComponent = () => {
  const scoringCategories = useSelector(
    (state: RootState) => state.session.questionPrompts,
  );

  return (
    <>
      <Navbar showbutton={true} title={"Judging Criteria"} hamburger={false} />
      <MainContainer>
        <ProjectScoringContainer>
          {scoringCategories.map((category, index) => (
            <CategoryContainer key={index}>
              <CategoryName>{category.categoryName}</CategoryName>
              <CategoryDescription>
                {category.categoryQuestion}
              </CategoryDescription>
            </CategoryContainer>
          ))}
        </ProjectScoringContainer>
      </MainContainer>
    </>
  );
};

export default JudgingCriteriaComponent;
