import { LoadingContainer, LoadingCircle } from "./index.styles";
const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <LoadingCircle />
    </LoadingContainer>
  );
};

export default LoadingComponent;
