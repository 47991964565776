import { useState, useEffect } from "react";

// Custom hook to detect mobile devices
export const useMobileDetect = (
  widthThreshold: number = 875
): boolean | null => {
  const [$ismobile, set$ismobile] = useState<boolean | null>(null);

  useEffect(() => {
    const handleResize = () => {
      set$ismobile(window.innerWidth <= widthThreshold);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [widthThreshold]);

  return $ismobile;
};
