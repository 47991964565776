import {
  InfoParagraph,
  InfoTextComponent,
  InfoTextContainer2,
} from "./index.styles";

const InfoText = () => {
  return (
    <InfoTextComponent>
      <InfoTextContainer2>
        Please note that your PIN should have been provided to you by the event
        organizers via email. If you are unable to locate your PIN, please
        contact an organizer for assistance.
        <br />
        <br />
        Please remember that your PIN is a secure piece of information and{" "}
        <b>should not be shared with anyone.</b>
      </InfoTextContainer2>
    </InfoTextComponent>
  );
};

export default InfoText;
