import Inter from "../shared/fonts/inter";
import { Navbar } from "../shared/nav-bar-component";
import CardButton from "../shared/nav-button-group-component/nav-button";
import ButtonContainer from "../shared/nav-button-group-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";

const SubmissionPageComponent = () => {
  const fontSize = {
    fontSize: "18px",
    paddingLeft: "20px",
  };

  const pendingProjects = useSelector(
    (state: RootState) => state.projects.pending,
  );
  const completedProjects = useSelector(
    (state: RootState) => state.projects.completed,
  );

  const navigate = useNavigate();

  const calendarIcon = "/assets/icons/calendar.svg";
  const checkIcon = "/assets/icons/check.svg";

  return (
    <div>
      <Navbar
        showbutton={true}
        popStack="/home"
        title="Submissions"
        hamburger={false}
      />
      <MainContainer>
        <ButtonContainer>
          <CardButton
            onClick={() => navigate("/submissions/pending")}
            $backgroundcolor="#FFD28E"
          >
            <img src={checkIcon} alt="Check Icon" />
            <Inter style={fontSize}>
              Pending Submissions ({pendingProjects.length})
            </Inter>
          </CardButton>
          <CardButton
            onClick={() => navigate("/submissions/completed")}
            $backgroundcolor="#C0F9EB"
          >
            <img src={calendarIcon} alt="Calendar Icon" />
            <Inter style={fontSize}>
              Completed Submissions ({completedProjects.length})
            </Inter>
          </CardButton>
        </ButtonContainer>
      </MainContainer>
    </div>
  );
};

export default SubmissionPageComponent;
