import React from "react";
import { Navbar } from "../shared/nav-bar-component";
import Inter from "../shared/fonts/inter";
import { useNavigate } from "react-router-dom";
import {
  ListContainer,
  SearchInput,
  HeaderTitle,
  TitleElement,
  ScoreElement,
  ProjectItem,
  ActionsElement,
  EditButton,
  Divider,
  ScrollProtection,
} from "./index.styles";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
}

type ProjectListProps = {
  title: string;
  projects: any[];
};

const ProjectListComponent: React.FC<ProjectListProps> = ({
  title,
  projects,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  React.useEffect(() => {
    const preventPullToRefresh = (e: any) => {
      if (window.scrollY < 1) {
        e.preventDefault();
        window.scrollTo(0, 2);
      }
    };

    document.addEventListener("touchmove", preventPullToRefresh, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", preventPullToRefresh);
    };
  }, []);

  const fontSize = {
    fontSize: "17px",
  };
  const emptyPrompt = {
    color: "#898989",
    fontSize: "17px",
  };
  const navigate = useNavigate();

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const displayTitle =
    filteredProjects.length === projects.length
      ? `${title} (${projects.length})`
      : `${title} (${filteredProjects.length} of ${projects.length})`;

  return (
    <ScrollProtection>
      <Navbar
        showbutton={true}
        popStack={title === "Submitted Projects" ? "/home" : "/submissions"}
        title={"Submissions"}
        hamburger={false}
      />
      <MainContainer>
        <ListContainer>
          <SearchInput
            type="text"
            placeholder="Search by project name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <HeaderTitle>
            <TitleElement>
              <Inter style={fontSize}>{displayTitle}</Inter>
            </TitleElement>

            {title === "Completed" && (
              <ScoreElement>
                <Inter style={fontSize}>Score</Inter>
              </ScoreElement>
            )}
            {title === "Submitted Projects" && (
              <ScoreElement>
                <Inter style={fontSize}>Overall Score</Inter>
              </ScoreElement>
            )}
            {title === "Completed" && (
              <ActionsElement>
                <Inter style={fontSize}>Action(s)</Inter>
              </ActionsElement>
            )}
          </HeaderTitle>
          <Divider />
          {filteredProjects.length >= 1 &&
            filteredProjects
              .filter((project) =>
                project.name.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              .sort((a, b) => {
                // sort by score in descending order if the title is "Completed"
                if (title === "Completed") {
                  return b.score - a.score;
                } else if (title === "Submitted Projects") {
                  return b.overallScore - a.overallScore;
                } else {
                  return 0;
                }
              })
              .map((project) => {
                const projectUrl = `/submissions/project/${project.id}`;
                const overallScore =
                  project.overallScore === undefined ? 0 : project.overallScore;
                const roundedOverallScore = Math.round(overallScore * 10) / 10;
                const overallScoreToOneDecimal = Number.isInteger(
                  roundedOverallScore,
                )
                  ? roundedOverallScore
                  : roundedOverallScore?.toFixed(1);
                return (
                  <ProjectItem
                    onClick={() => navigate(projectUrl)}
                    key={project.id}
                  >
                    <TitleElement>
                      <Inter style={fontSize}>
                        {truncateText(project.name, 20)}
                      </Inter>
                    </TitleElement>
                    {title === "Completed" && (
                      <ScoreElement>
                        <Inter style={fontSize}>{project.score}</Inter>
                      </ScoreElement>
                    )}{" "}
                    {title === "Submitted Projects" && (
                      <ScoreElement>
                        <Inter style={fontSize}>
                          {overallScoreToOneDecimal}
                        </Inter>
                      </ScoreElement>
                    )}{" "}
                    {title === "Completed" && (
                      <ActionsElement>
                        <EditButton
                          onClick={(e) => {
                            const editProjectScoringUrl = `/submissions/project/${project.id}/scoring`;
                            e.stopPropagation();
                            navigate(editProjectScoringUrl);
                          }}
                        >
                          Edit
                        </EditButton>
                      </ActionsElement>
                    )}
                  </ProjectItem>
                );
              })}
          {filteredProjects.length < 1 && (
            <ProjectItem $backgroundcolor="white">
              <Inter style={emptyPrompt}> Nothing to see here!</Inter>
            </ProjectItem>
          )}
        </ListContainer>
      </MainContainer>
    </ScrollProtection>
  );
};

export default ProjectListComponent;
