import ProjectListComponent from "../../components/project-list-component";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";

type ProjectListProps = {
  title: string;
};

const ProjectList: React.FC<ProjectListProps> = ({ title }) => {
  const { pending, inProgress, completed } = useSelector(
    (state: RootState) => state.projects
  );

  let projects: any[];
  switch (title.toLowerCase()) {
    case "pending":
      projects = pending;
      break;
    case "in-progress":
      projects = inProgress;
      break;
    case "completed":
      projects = completed;
      break;
    case "submitted projects":
      projects = [...pending, ...completed];
      break;
    default:
      projects = [];
  }

  return <ProjectListComponent title={title} projects={projects} />;
};

export default ProjectList;
