import React, { useState, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import { getResults } from "../../../../utils/api";
import { setResults, setResultsOpenStatus } from "../../../../reducers/session";
import ErrorComponent from "../../../shared/error-screen-component";
import LoadingComponent from "../../../shared/loading-screen-component";

interface LoadResultsProps {
  children: ReactNode;
}
const LoadResultsData: React.FC<LoadResultsProps> = ({ children }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state: RootState) => state.session.id);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getResults(authToken);
        if (response.errorMessage) {
          console.error("Error:", response.errorMessage);
          if (response.errorMessage.includes("results are not yet enabled")) {
            dispatch(setResultsOpenStatus(false));
          } else {
            setError(response.errorMessage)
          }
        } else {
          const resultsList = response.results;
          dispatch(setResults(resultsList));
          dispatch(setResultsOpenStatus(true));
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  if (loading) return <LoadingComponent/>;
  if (error) return <ErrorComponent message={error}/>;

  return <>{children}</>;
};

export default LoadResultsData;
