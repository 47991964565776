import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar } from "../shared/nav-bar-component";
import { Project, RootState } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { NavigateButton } from "../shared/buttons/index.styles";
import Inter from "../../components/shared/fonts/inter";
import {
  Banner,
  ProjectName,
  ButtonContainer,
  DevPostButton,
  ProjectInfoContainer,
  SectionHeader,
  ExistingScorePrompt,
  Member,
  MemberName,
  MembersList,
  LinkImage,
} from "./index.styles";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";
import {
  GenericContainer,
  PillContainer,
  WrapperContainer,
} from "../../components/shared/containers";
import theme from "../../utils/theme";
import BricolageGrotesque, {
  BricolageGrotesqueFontWeight,
} from "../../components/shared/fonts/bricolage-grotesque";
import ModalDialog from "../../components/shared/modal-dialog-component";

interface Note {
  author: string;
  note: string;
}

type ProjectOverviewProps = {
  project: Project;
};

const ProjectOverviewComponent: React.FC<ProjectOverviewProps> = ({
  project,
}) => {
  const backgroundColors = [
    "#FFD28E",
    "#C0F9EB",
    "#E3A1E3",
    "#E1EBFF",
    "#A050A0",
    "#2850A0",
  ];
  const navigate = useNavigate();
  const [favourite, setFavourite] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalMessage =
    "Are you sure you wish to begin judging? You will have 5 minutes to finalize your scoring.";
  const judgingDisabled = !useSelector(
    (state: RootState) => state.session.judgingOpen,
  );
  const judgesFromChallengeList = useSelector(
    (state: RootState) => state.session.usersList,
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDevPostLinkClick = (link: string) => {
    window.open(link);
  };

  const blueLinkIcon = "/assets/icons/blueLink.svg";
  const pillCheckIcon = "/assets/icons/pillCheck.svg";
  const pillBlackCheckIcon = "/assets/icons/blackCheckMark.svg";

  const projectScoringUrl = `/submissions/project/${project.id}/scoring`;

  const confirmAction = () => {
    navigate(projectScoringUrl);
  };

  const getMostRecentNotesByAuthors = (
    proj_notes: any,
    judgeList: string[],
  ) => {
    let lastNotesByAuthor: { [author: string]: Note } = {};

    proj_notes.forEach((note: Note) => {
      if (judgeList.includes(note.author)) {
        lastNotesByAuthor[note.author] = note;
      }
    });

    return Object.values(lastNotesByAuthor);
  };

  const allNotesForProject = getMostRecentNotesByAuthors(
    project.allNotes,
    judgesFromChallengeList,
  );

  const overallScore =
    project.overallScore === undefined ? 0 : project.overallScore;
  const roundedOverallScore = Math.round(overallScore * 10) / 10;
  const overallScoreToOneDecimal = Number.isInteger(roundedOverallScore)
    ? roundedOverallScore
    : roundedOverallScore?.toFixed(1);
  const onFavouriteToggle = () => {
    setFavourite(!favourite);
  };

  const InterStyle = {
    fontSize: "13px",
    color: theme.white,
  };

  const ScoreInterStyle = {
    ...InterStyle,
    color: theme.black,
  };

  const HeaderText = {
    fontSize: "30px",
    textAlign: "left",
    letterSpacing: "0rem",
    margin: "0px",
  };

  return (
    <>
      <Navbar
        showbutton={true}
        popStack={
          judgingDisabled
            ? "/submissions/all"
            : project.score >= 0
              ? "/submissions/completed"
              : "/submissions/pending"
        }
        title="Project Overview"
        hamburger={false}
        favourite={favourite}
        onFavouriteClick={onFavouriteToggle}
      />
      <MainContainer>
        <WrapperContainer>
          {project.score < 0 && (
            <PillContainer
              icon={<img src={pillCheckIcon} />}
              text={<Inter style={InterStyle}>Awaiting Judgement</Inter>}
              backgroundColor={theme.black}
            />
          )}
          {!judgingDisabled && project.score >= 0 && (
            <PillContainer
              icon={<img src={pillBlackCheckIcon} />}
              text={
                <Inter style={ScoreInterStyle}>SCORE: {project.score}</Inter>
              }
              backgroundColor={theme.secondaryOrange}
            />
          )}
          <GenericContainer>
            <BricolageGrotesque
              fontWeight={BricolageGrotesqueFontWeight.Bold}
              style={{
                ...HeaderText,
                color: theme.black,
              }}
            >
              {project.name}
            </BricolageGrotesque>
            <p>{project.description}</p>
            <DevPostButton
              onClick={() => onDevPostLinkClick(project.devPostUrl)}
            >
              View on devpost
              <LinkImage src={blueLinkIcon} alt="Link" />
            </DevPostButton>
          </GenericContainer>
          <GenericContainer>
            <SectionHeader>{`Members (${project.members.length})`}</SectionHeader>
            <MembersList>
              {project.members.map((member, index) => (
                <Member key={index}>
                  <MemberName>{member.name}</MemberName>
                  <div>{member.email}</div>
                </Member>
              ))}
            </MembersList>
          </GenericContainer>

          <ProjectInfoContainer>
            {!judgingDisabled && project.score >= 0 && project.notes && (
              <>
                <SectionHeader>Notes</SectionHeader>
                <p>{project.notes}</p>
              </>
            )}

            {judgingDisabled && allNotesForProject.length > 0 && (
              <>
                <SectionHeader>Notes</SectionHeader>
                <MembersList>
                  {allNotesForProject.map((note, index) => (
                    <Member key={index}>
                      <MemberName>{note.author}</MemberName>
                      <div>{note.note}</div>
                    </Member>
                  ))}
                </MembersList>
              </>
            )}

            {judgingDisabled && project.overallScore !== null && (
              <ExistingScorePrompt>
                The overall score of this project: {overallScoreToOneDecimal}
              </ExistingScorePrompt>
            )}

            {!judgingDisabled && (
              <ButtonContainer>
                <NavigateButton onClick={() => setIsModalOpen(true)}>
                  {project.score >= 0
                    ? "Update Score"
                    : "Start Judging (5 Mins)"}
                </NavigateButton>
              </ButtonContainer>
            )}
          </ProjectInfoContainer>
        </WrapperContainer>
      </MainContainer>
      <ModalDialog
        $isopen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          setIsModalOpen(false);
          confirmAction();
        }}
        header="Start Judging"
        message={modalMessage}
        confirmText="Start"
        cancelText="Cancel"
      />
    </>
  );
};

export default ProjectOverviewComponent;
