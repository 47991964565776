import React, { useEffect, useState } from "react";
import Inter from "../fonts/inter";
import { ModalOverlay, ModalContent, CloseButton } from "./index.styles";
import { NavigateButton, NavigateButtonHollow } from "../buttons/index.styles";

type ModalProps = {
  $isopen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header?: string;
  scorePrompt?: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
};

const ModalDialog: React.FC<ModalProps> = ({
  $isopen,
  onClose,
  onConfirm,
  header,
  scorePrompt,
  message,
  confirmText = "Yes",
  cancelText = "No",
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if ($isopen) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => setIsAnimating(false), 300); // Change this to match the animation duration we want
      return () => clearTimeout(timer);
    }
  }, [$isopen]);

  const headerStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "16px",
    lineHeight: 1.2,
  };
  const scorePromptStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "8px",
  };
  const messageStyle = {
    fontSize: "16px",
    marginBottom: "12px",
    lineHeight: 1.5,
  };

  if (!$isopen && !isAnimating) return null;

  return (
    <ModalOverlay
      onClick={onClose}
      style={{ opacity: $isopen ? 1 : 0, transition: "opacity 0.3s ease-out" }}
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {<Inter style={headerStyle}>{header}</Inter>}
        {scorePrompt && <Inter style={scorePromptStyle}>{scorePrompt}</Inter>}
        <Inter style={messageStyle}>{message}</Inter>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigateButtonHollow
            onClick={onClose}
            style={{ marginRight: "10px", flex: 1 }}
          >
            {cancelText}
          </NavigateButtonHollow>
          <NavigateButton onClick={onConfirm} style={{ flex: 1 }}>
            {confirmText}
          </NavigateButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalDialog;
