import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "../reducers/project";
import sessionReducer from "../reducers/session";

const store = configureStore({
  reducer: {
    projects: projectsReducer,
    session: sessionReducer,
  },
});

export default store;
