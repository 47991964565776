import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { useMobileDetect } from "../../../../hooks/useMobileDetect";

// EXAMPLE USAGE:
//     <BricolageGrotesque fontWeight={BricolageGrotesqueFontWeight.Bold} style={{ ...fontSize, marginBottom: "5%" }}>
//         UofTHacks 12
//     </BricolageGrotesque>

export enum BricolageGrotesqueFontWeight {
  ExtraLight = "ExtraLight",
  Light = "Light",
  Regular = "Regular",
  Medium = "Medium",
  SemiBold = "SemiBold",
  Bold = "Bold",
  ExtraBold = "ExtraBold",
}

type StyledBricolageGrotesqueTextProps = {
  style?: any;
  $mobile?: boolean | null;
  fontWeight?: BricolageGrotesqueFontWeight;
};

const getFontSrc = (fontWeight: BricolageGrotesqueFontWeight) => {
  switch (fontWeight) {
    case BricolageGrotesqueFontWeight.ExtraLight:
      return "/fonts/BricolageGrotesque-ExtraLight.woff2";
    case BricolageGrotesqueFontWeight.Light:
      return "/fonts/BricolageGrotesque-Light.woff2";
    case BricolageGrotesqueFontWeight.Regular:
      return "/fonts/BricolageGrotesque-Regular.woff2";
    case BricolageGrotesqueFontWeight.Medium:
      return "/fonts/BricolageGrotesque-Medium.woff2";
    case BricolageGrotesqueFontWeight.SemiBold:
      return "/fonts/BricolageGrotesque-SemiBold.woff2";
    case BricolageGrotesqueFontWeight.Bold:
      return "/fonts/BricolageGrotesque-Bold.woff2";
    case BricolageGrotesqueFontWeight.ExtraBold:
      return "/fonts/BricolageGrotesque-ExtraBold.woff2";
    default:
      return "/fonts/BricolageGrotesque-Regular.woff2";
  }
};

const createBricolageGrotesqueFont = (fontWeight: BricolageGrotesqueFontWeight) => css`
  @font-face {
    font-family: "BricolageGrotesque-${fontWeight}";
    src: url(${getFontSrc(fontWeight)}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }
`;

const StyledBricolageGrotesqueText = styled.p<StyledBricolageGrotesqueTextProps>`
  ${(props) => createBricolageGrotesqueFont(props.fontWeight || BricolageGrotesqueFontWeight.Regular)};
  font-family: ${(props) => (`BricolageGrotesque-${props.fontWeight || BricolageGrotesqueFontWeight.Regular}`)};
  color: var(--dark-blue, #2850a0);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.$mobile ? "48px" : "96px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3rem;
`;

type SubjectivityProps = StyledBricolageGrotesqueTextProps & {
  children: ReactNode;
};

const BricolageGrotesque: React.FC<SubjectivityProps> = ({
  children,
  style,
  fontWeight = BricolageGrotesqueFontWeight.Regular,
  ...props
}) => {
  const $ismobile = useMobileDetect();
  return (
    <StyledBricolageGrotesqueText
      style={style}
      $mobile={$ismobile}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </StyledBricolageGrotesqueText>
  );
};

export default BricolageGrotesque;
