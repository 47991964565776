import ProjectOverviewComponent from "../../components/project-overview-component";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../components/shared/nav-bar-component";
import Inter from "../../components/shared/fonts/inter";
import styled from "styled-components";

type ProjectOverviewProps = {};

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const ProjectOverview: React.FC<ProjectOverviewProps> = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const projectIdValue = projectId || "";
  const pendingProjects = useSelector(
    (state: RootState) => state.projects.pending
  );
  const inProgressProjects = useSelector(
    (state: RootState) => state.projects.inProgress
  );
  const completedProjects = useSelector(
    (state: RootState) => state.projects.completed
  );

  const allProjects = [
    ...pendingProjects,
    ...inProgressProjects,
    ...completedProjects,
  ];
  const project = allProjects.find(
    (p) => p.id === parseInt(projectIdValue, 10)
  );
  return (
    <div>
      {project ? (
        <ProjectOverviewComponent project={project} />
      ) : (
        <>
          <Navbar
            showbutton={true}
            popStack="/submissions"
            title="Project Overview"
            hamburger={false}
          />
          <NotFoundContainer>
            <Inter>Can't find a project with that ID at this time.</Inter>
            <StyledButton onClick={() => navigate("/home")}>
              Return Home
            </StyledButton>
          </NotFoundContainer>
        </>
      )}
    </div>
  );
};

export default ProjectOverview;
