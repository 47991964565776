import styled from "styled-components";
import theme from "../../../utils/theme";

const PinGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 0.1rem;
  padding: 0 1rem;
  flex-wrap: nowrap;
  min-height: 35px;
  overflow: hidden;
`;

const PinInput = styled.input`
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  font-size: 2rem;
  color: transparent;
  text-shadow: 0 0 0 ${theme.gray};

  &::placeholder {
    color: transparent;
  }

  flex-shrink: 0;
  flex-grow: 0;
`;

export { PinGroup, PinInput };
