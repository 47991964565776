import React from "react";
import CardButton from "../shared/nav-button-group-component/nav-button";
import ButtonContainer from "../shared/nav-button-group-component";
import Inter from "../shared/fonts/inter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { Navbar } from "../shared/nav-bar-component";
import { Prompt } from "./index.styles";
import ModalDialog from "../shared/modal-dialog-component";
import { MainContainer } from "../shared/nav-bar-component/index.styles";

type MainPageProps = {
  name?: string;
};

const MainPageComponent: React.FC<MainPageProps> = () => {
  const userName = useSelector((state: RootState) => state.session.userName);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const resultsDisabled = !useSelector(
    (state: RootState) => state.session.resultsOpen,
  );
  const judgingDisabled = !useSelector(
    (state: RootState) => state.session.judgingOpen,
  );

  const dialogMessage = "Are you sure you want to log out?";

  const navigate = useNavigate();

  const fontSize = {
    paddingLeft: "20px",
    fontSize: "20px",
  };

  const titleStyle = {
    fontSize: "20px",
    maxWidth: "640px",
    width: "80%",
    textAlign: "left",
  };

  const navBarTitle = "UofTHacks Judging Portal";
  const title = `Welcome back, ${userName}!`;

  const scheduleLink =
    "https://docs.google.com/spreadsheets/d/1_Uvh8A2XtpwUBKYaGJjg97xdA5Y5R0sCU-6qSrNf6ck/edit#gid=0";
  const handleScheduleClick = (link: string) => {
    window.open(link);
  };

  const gavelIcon = "/assets/icons/gavel.svg";
  const calendarIcon = "/assets/icons/calendar.svg";
  const checkIcon = "/assets/icons/check.svg";
  const lockIcon = "/assets/icons/lock.svg";
  const trophyIcon = "/assets/icons/trophy.svg";

  const onBackClick = () => {
    setDialogOpen(true);
  };

  return (
    <div>
      <Navbar
        title={navBarTitle}
        hamburger={false}
        showbutton={true}
        onBackClick={onBackClick}
      />
      <MainContainer>
        <ButtonContainer>
          <Inter style={titleStyle}>{title}</Inter>
          <CardButton
            $backgroundcolor="#E1EBFF"
            onClick={() => navigate("/submissions")}
          >
            <img src={checkIcon} alt="Check Icon" />
            <Inter style={fontSize}>Submissions</Inter>
          </CardButton>
          <CardButton
            onClick={() => navigate("/judging-criteria")}
            $backgroundcolor="#FFD28E"
          >
            <img src={gavelIcon} alt="Gavel Icon" />
            <Inter style={fontSize}>Judging Criteria</Inter>
          </CardButton>
          <CardButton
            onClick={() => handleScheduleClick(scheduleLink)}
            $showlinkicon={true}
            $backgroundcolor="#C0F9EB"
          >
            <img src={calendarIcon} alt="Calendar Icon" />
            <Inter style={fontSize}>Schedule</Inter>
          </CardButton>

          <CardButton
            disabled={resultsDisabled}
            onClick={() => navigate("/results")}
            $backgroundcolor={resultsDisabled ? "#EAEAEA" : "#E3A1E3"}
          >
            <img
              src={resultsDisabled ? lockIcon : trophyIcon}
              alt="Trophy Icon"
            />
            <Inter style={fontSize}>Results</Inter>
          </CardButton>
          {judgingDisabled && <Prompt>The judging period is closed.</Prompt>}
          {resultsDisabled && (
            <Prompt>
              Results are disabled until AFTER judging is completed.
            </Prompt>
          )}
        </ButtonContainer>
      </MainContainer>
      <ModalDialog
        $isopen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setDialogOpen(false);
          navigate("/logout/");
        }}
        message={dialogMessage}
      />
    </div>
  );
};

export default MainPageComponent;
