//const API_BASE_ENDPOINT_URL = 'http://localhost:8000'
const API_BASE_ENDPOINT_URL = 'https://api.uofthacks.com'

export const fetchJudgeList = async (pin: any) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/judgelist`);
    url.searchParams.append('company_id', pin);

    const response = await fetch(url.toString());

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Bad request');
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching judge list:', error);
    throw error;
  }
};

export const registerJudge = async (pin: string, judgeName: string) => {
  try {
    const url = `${API_BASE_ENDPOINT_URL}/12/judge/register`;

    const requestBody = {
      company_id: pin,
      judges_name: judgeName
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    };

    const response = await fetch(url, requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Bad request');
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error registering judge:', error);
    throw error;
  }
};


export const loginJudge = async (pin: string, judgeName: string) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/login`);

    const requestBody = {
      company_id: pin,
      judges_name: judgeName
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Bad request');
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error logging the judge in:', error);
    throw error;
  }
};

export const getProjects = async (authToken: any) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/projects?query=`);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        "errorMessage": errorData.message
      }
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const getResults = async (authToken: any) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/results`);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        "errorMessage": errorData.message
      }
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching results:', error);
    throw error;
  }
};

export const markProj = async (authToken: any, projId: any, projMark: any, projNotes: any) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/updatescore`);

    const requestBody = {
      proj_id: projId,
      proj_mark: projMark,
      proj_notes: projNotes
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        "errorMessage": errorData.message
      }
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating project score:', error);
    throw error;
  }
};


