import styled, { keyframes } from "styled-components";

type ButtonVariant = "yes" | "no";

const slideUp = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  padding: 0;
  line-height: 1;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${slideUp} 0.3s ease-out;
`;

const StyledButton = styled.button<{ variant: ButtonVariant }>`
  background-color: ${(props) => (props.variant === "yes" ? "black" : "white")};
  color: ${(props) => (props.variant === "yes" ? "white" : "black")};
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 10px;
  border: 1px solid black;

  &:hover {
    background-color: ${(props) =>
      props.variant === "yes" ? "#A8E6CF" : "#FF8A80"};
    color: ${(props) => (props.variant === "yes" ? "black" : "white")};
  }
`;

export { StyledButton, ModalContent, ModalOverlay, CloseButton };
