import styled from "styled-components";

const InfoTextComponent = styled.div`
  width: 100%;
  max-width: 600px;
  flex-shrink: 1.5;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const InfoParagraph = styled.p`
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  width: 100%;
  margin: 0;
`;

const InfoTextContainer2 = styled.div`
  width: 100%;
  color: var(--neutral-500, #7d7b7a);
  font-size: 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  padding: 5px;
`;
export { InfoParagraph, InfoTextComponent, InfoTextContainer2 };
