import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project, ProjectsState } from "@/utils/types";

// const initialState = {
//   pending: [],
//   inProgress: [],
//   completed: [],
// };
// SAMPLE DATA FOR NOW
const initialState: ProjectsState = {
  pending: [],
  inProgress: [],
  completed: [],
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetProjects: () => initialState,
    setProjects: (state, action: PayloadAction<typeof initialState>) => {
      return action.payload;
    },
    setPendingProjects: (state, action: PayloadAction<Project[]>) => {
      state.pending = action.payload;
    },
    setCompletedProjects: (state, action: PayloadAction<Project[]>) => {
      state.completed = action.payload;
    },

    setOverallScoresOfProjects: (state, action: PayloadAction<any[]>) => {
      const results = action.payload;
      if (results) {
        results.forEach((result) => {
          const pendingProject = state.pending.find(
            (project) => project.name === result.proj_name
          );
          if (pendingProject) {
            pendingProject.overallScore = result.proj_mark;
          }

          const completedProject = state.completed.find(
            (project) => project.name === result.proj_name
          );
          if (completedProject) {
            completedProject.overallScore = result.proj_mark;
          }
        });
      }
    },

    updateProjectScore: (
      state,
      action: PayloadAction<{ projectId: number; score: number }>
    ) => {
      let projectToUpdate;
      let foundIn = "";
      const pendingIndex = state.pending.findIndex(
        (project) => project.id === action.payload.projectId
      );
      if (pendingIndex !== -1) {
        projectToUpdate = state.pending[pendingIndex];
        state.pending.splice(pendingIndex, 1);
        foundIn = "pending";
      }

      if (!projectToUpdate) {
        const inProgressIndex = state.inProgress.findIndex(
          (project) => project.id === action.payload.projectId
        );
        if (inProgressIndex !== -1) {
          projectToUpdate = state.inProgress[inProgressIndex];
          state.inProgress.splice(inProgressIndex, 1);
          foundIn = "inProgress";
        }
      }

      if (!projectToUpdate) {
        const completedIndex = state.completed.findIndex(
          (project) => project.id === action.payload.projectId
        );
        if (completedIndex !== -1) {
          projectToUpdate = state.completed[completedIndex];
          foundIn = "completed";
        }
      }

      if (projectToUpdate) {
        projectToUpdate.score = action.payload.score;
        if (foundIn !== "completed") {
          state.completed.push(projectToUpdate);
        }
      }
    },
    moveToInProgress: (state, action: PayloadAction<Project>) => {
      state.inProgress.push(action.payload);
      state.pending = state.pending.filter(
        (project) => project.id !== action.payload.id
      );
    },
    moveToCompleted: (state, action: PayloadAction<Project>) => {
      state.completed.push(action.payload);
      state.inProgress = state.inProgress.filter(
        (project) => project.id !== action.payload.id
      );
    },
  },
});

export const {
  setProjects,
  setPendingProjects,
  setCompletedProjects,
  setOverallScoresOfProjects,
  moveToInProgress,
  moveToCompleted,
  updateProjectScore,
  resetProjects,
} = projectsSlice.actions;
export default projectsSlice.reducer;
