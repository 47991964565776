import Inter from "../fonts/inter";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Subjectivity from "../fonts/subjectivity";
import BackIcon from "./back-icon";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import {
  NavLink,
  NavLinkItem,
  NavbarContainer,
  MobileMenu,
  MobileNavLinkItem,
  BackButton,
  HamburgerMenu,
} from "./index.styles";
import styled from "styled-components";

type NavbarProps = {
  title?: string;
  popStack?: string;
  hamburger?: boolean;
  favourite?: boolean | undefined;
  showbutton?: boolean;
  onBackClick?: () => void;
  onFavouriteClick?: () => void;
  isLogOut?: boolean;
};

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  transform: translateX(-10px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .not-favourite-icon {
    transform: scale(1.7); /* Scale and shift left by 10px */
  }
`;

const Navbar: React.FC<NavbarProps> = ({
  title,
  popStack,
  hamburger,
  favourite,
  onBackClick,
  onFavouriteClick,
  showbutton,
  isLogOut,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const $ismobile = useMobileDetect();

  const [mobileMenuNode, setMobileMenuNode] = useState<HTMLSpanElement | null>(
    null,
  );

  const [hamburgerNode, setHamburgerNode] = useState<HTMLDivElement | null>(
    null,
  );
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const favouriteIcon = "/assets/icons/favourite.svg";
  const notFavouriteIcon = "/assets/icons/notFavourite.svg";

  const fontSize = {
    fontSize: $ismobile ? "18px" : "25px",
    color: "#0a165a",
    letterSpacing: "0rem",
    fontWeight: "900",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    const handleClickOutside = (event: any) => {
      if (
        isMenuOpen &&
        mobileMenuNode &&
        !mobileMenuNode.contains(event.target) &&
        !hamburgerNode?.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen, hamburgerNode, mobileMenuNode]);

  const MobileInterStyle = () => ({
    fontSize: "15px",
  });

  const navigate = useNavigate();

  const navbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: title ? "#FEFEFF" : undefined,
  };

  return (
    <>
      <NavbarContainer style={navbarStyle} $genericVariant={Boolean(title)}>
        <div>
          {/* <Link to={popStack ? popStack : "/home"}>
            <BackButton>
              <Inter style={fontSize}>{"<"}</Inter>
            </BackButton>
          </Link> */}
          <BackButton
            $genericVariant={Boolean(title)}
            $showbutton={showbutton}
            onClick={() => {
              if (isLogOut) {
                navigate("/logout");
              } else if (onBackClick) {
                onBackClick();
              } else {
                navigate(popStack ? popStack : "/home");
              }
            }}
          >
            <BackIcon />
          </BackButton>
        </div>
        <div>
          <Inter style={fontSize}>{title}</Inter>
        </div>

        {favourite !== undefined && (
          <IconContainer onClick={onFavouriteClick}>
            {favourite === true ? (
              <img src={favouriteIcon} alt="Favourite Icon" />
            ) : (
              <img
                src={notFavouriteIcon}
                alt="Not Favourite Icon"
                className="not-favourite-icon"
              />
            )}
          </IconContainer>
        )}

        {favourite === undefined && (
          <div>
            <HamburgerMenu
              ref={(node) => setHamburgerNode(node)}
              $showhamburger={hamburger}
              onClick={toggleMenu}
            >
              ☰
            </HamburgerMenu>
          </div>
        )}
      </NavbarContainer>

      <span ref={(node) => setMobileMenuNode(node)}>
        <MobileMenu open={isMenuOpen} $ismobile={$ismobile}>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/home")}>
                <Inter style={MobileInterStyle()}>Home</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/submissions")}>
                <Inter style={MobileInterStyle()}>Submissions</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/judging-criteria")}>
                <Inter style={MobileInterStyle()}>Judging Criteria</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/results")}>
                <Inter style={MobileInterStyle()}>Results</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
          <MobileNavLinkItem>
            <NavLinkItem>
              <NavLink onClick={() => navigate("/logout")}>
                <Inter style={MobileInterStyle()}>Log Out</Inter>
              </NavLink>
            </NavLinkItem>
          </MobileNavLinkItem>
        </MobileMenu>
      </span>
    </>
  );
};

export { Navbar, NavbarContainer };
