import styled from "styled-components";
import theme from "../../utils/theme";
import Inter from "../../components/shared/fonts/inter";
import { NavigateButton } from "../../components/shared/buttons/index.styles";

type CustomScoreDisplayProps = {
  $invertcolour?: boolean;
};

const Slider = styled.input<{ $isFilledCompletely: boolean }>`
  -webkit-appearance: none;
  width: 100%;
  margin: 0px 0;
  padding: 0;
  background: transparent;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: ${(props) =>
      props.$isFilledCompletely ? theme.secondaryOrange : "#BFBCBB"};
    border-radius: 5px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px;
    background: ${(props) =>
      props.$isFilledCompletely ? theme.secondaryOrange : "#BFBCBB"};
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: black;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -7.5px;
  }

  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background: theme . secondaryOrange;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const ProjectScoringContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #007d7d;
  border-radius: 8px;
  margin-top: 20px;
  width: 80%;
  max-width: 300px;
`;

const NoteImage = styled.img`
  margin-right: 10px;
  width: 13px;
  height: 13px;
`;

const ScoreLabel = styled.div`
  padding: 30px 30px 0px 30px;
  font-size: 30px;
  font-weight: bold;
  color: white;
`;

export const ScoreFractionContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const AverageScore = styled.span<CustomScoreDisplayProps>`
  font-size: 60px;
  font-weight: bold;
  color: ${(props) => (props.$invertcolour ? "black" : "white")};
  padding-bottom: 20px;
  margin-right: 5px;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  overflow-x: hidden;
`;

export const TotalScore = styled.span<CustomScoreDisplayProps>`
  font-size: 30px;
  color: ${(props) => (props.$invertcolour ? "black" : "white")};
`;

const CategoryContainer = styled.div`
  background-color: ${theme.white};
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const CommentsContainer = styled(CategoryContainer)`
  background-color: var(--Light-Purple, #e3a1e3);
`;

const CommentsTextbox = styled.textarea`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const CategoryName = styled(Inter)`
  margin-top: 0px;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 5px;
`;

const CategoryDescription = styled.p`
  margin-bottom: 10px;
`;

const SliderContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const ScoreDisplay = styled.span`
  margin-left: 10px;
`;

const ProjectName = styled.h1`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 40px;
  font-size: 16px;
`;

const ProjectMembers = styled.div`
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const SubmitButton = styled(NavigateButton)`
  width: 100%;
  margin: 0px;
  align-self: center;
`;

const NotesButton = styled(NavigateButton)`
  background-color: ${theme.white};
  color: ${theme.darkBlue};
  width: 100%;
  border: 2px solid ${theme.darkBlue};
  font-size: 18px;
  cursor: pointer;
  align-self: center;
  margin: 0px;
`;

export {
  ErrorMessage,
  ProjectMembers,
  ProjectName,
  ScoreDisplay,
  SliderContainer,
  CategoryContainer,
  CategoryDescription,
  CategoryName,
  CenterContainer,
  ScoreLabel,
  Slider,
  ProjectScoringContainer,
  ScoreContainer,
  SubmitButton,
  NoteImage,
  CommentsContainer,
  CommentsTextbox,
  NotesButton,
};
