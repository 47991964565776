import React from "react";
import {
  BackgroundWrapper,
  TopRightIcon,
  BottomLeftIcon,
  TopLeftIcon,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";

interface BackgroundProps {
  isLanding?: boolean;
}

const Background = ({ isLanding }: BackgroundProps) => {
  const isMobile = useMobileDetect();
  return (
    <BackgroundWrapper isLanding={isLanding}>
      {isLanding && (
        <TopLeftIcon
          isMobile={isMobile}
          src="/assets/generic-background/logo.svg"
          alt="Top Left Icon"
        />
      )}
      <TopRightIcon
        isMobile={isMobile}
        src="/assets/generic-background/purpleStar.png"
        alt="Top Right Icon"
      />
      <BottomLeftIcon
        isMobile={isMobile}
        src="/assets/generic-background/orangeStar.png"
        alt="Bottom Left Icon"
      />
    </BackgroundWrapper>
  );
};

export default Background;
