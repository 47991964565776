import styled from "styled-components";
import theme from "../../../utils/theme";

const NavigateButton = styled.button`
  background: var(--primary-300, #2a398c);
  color: white;
  border: none;
  border-radius: 10px;
  width: calc(100% - 40px);
  max-width: 400px;
  height: 50px;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  transition: background-color 0.3s;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    background: ${theme.blueButtonHover};
  }

  &:disabled {
    background: #b0b0b0;
    color: #f0f0f0;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const NavigateButtonHollow = styled.button`
  background: white;
  color: var(--primary-300, #2a398c);
  border: 2px solid var(--primary-300, #2a398c); // Added border width and style
  border-radius: 10px;
  width: calc(100% - 40px);
  max-width: 400px;
  height: 50px;
  font-size: 25px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  transition: background-color 0.3s;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    background: ${theme.offWhite};
  }

  &:disabled {
    background: #b0b0b0;
    color: #f0f0f0;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export { NavigateButton, NavigateButtonHollow };
