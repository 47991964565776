import React from "react";
import styled from "styled-components";
import theme from "../../../utils/theme";

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const TooltipText = styled.div`
  visibility: hidden;
  width: 200px;
  background-color: ${theme.white};
  font-size: 15px;
  color: ${theme.black};
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: -5px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.lightGray};

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
  }
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

const InfoIcon = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${theme.white}; /* Customize icon color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.black};
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.black};
  font-size: 12px;
  font-weight: bold;
`;

export const Tooltip: React.FC<{ text: string }> = ({ text }) => {
  return (
    <TooltipContainer>
      <IconWrapper>
        <InfoIcon>i</InfoIcon>
        <TooltipText>{text}</TooltipText>
      </IconWrapper>
    </TooltipContainer>
  );
};
