import { BackIconComponent } from "./index.styles";

const BackIcon = () => {
  return (
    // will route this to landing page. can move this to index.tsx of login-form. later task.
    <BackIconComponent>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47782 0.334735C8.90739 0.781049 8.90739 1.50467 8.47782 1.95098L3.75564 6.85714H15.4C16.0075 6.85714 16.5 7.36882 16.5 8C16.5 8.63118 16.0075 9.14286 15.4 9.14286H3.75564L8.47782 14.049C8.90739 14.4953 8.90739 15.219 8.47782 15.6653C8.04824 16.1116 7.35176 16.1116 6.92218 15.6653L0.322183 8.80812C-0.107394 8.36181 -0.107394 7.63819 0.322183 7.19188L6.92218 0.334735C7.35176 -0.111578 8.04824 -0.111578 8.47782 0.334735Z"
          fill="black"
        />
      </svg>
    </BackIconComponent>
  );
};
export default BackIcon;
