import React from "react";
import styled from "styled-components";

interface PillContainerProps {
  icon: React.ReactNode;
  text: React.ReactNode;
  backgroundColor?: string;
  style?: React.CSSProperties;
}

const StyledPillContainer = styled.div<{ backgroundColor?: string }>`
  display: inline-flex;
  max-width: fit-content;
  height: 20px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#e0e0e0"};
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const PillRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PillContainer: React.FC<PillContainerProps> = ({
  icon,
  text,
  backgroundColor,
  style,
}) => {
  return (
    <StyledPillContainer backgroundColor={backgroundColor} style={style}>
      <IconWrapper>{icon}</IconWrapper>
      {text}
    </StyledPillContainer>
  );
};

interface GenericContainerProps {
  children: React.ReactNode;
  className?: string; // To allow further customization if needed
}

const StyledGenericContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const GenericContainer: React.FC<GenericContainerProps> = ({
  children,
  className,
}) => {
  return (
    <StyledGenericContainer className={className}>
      {children}
    </StyledGenericContainer>
  );
};

const StyledWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px;
  margin-bottom: 0px;
`;

interface WrapperContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const WrapperContainer: React.FC<WrapperContainerProps> = ({
  children,
  className,
}) => {
  return (
    <StyledWrapperContainer className={className}>
      {children}
    </StyledWrapperContainer>
  );
};
