import styled from "styled-components";
import InterSemiBold from "../shared/fonts/inter-semi-bold";
import theme from "../../utils/theme";
import { DropdownButton } from "../../components/user-select-component/dropdown-component/index.styles";

type LoginProps = {
  $ismobile?: boolean | null;
};

const PageContainer = styled.div<LoginProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 60px;
`;
const DropdownContainer = styled.div`
  border-radius: 4px;
  position: relative;
  width: 100%;
`;

const NavbarContainer = styled.div`
  background-color: ${theme.offWhite};
`;

const TextContainer = styled.div<LoginProps>`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const greyH3 = styled.h3`
  color: ${theme.gray};
  font-size: 16px;
`;

const BackIconContainer = styled.div`
  margin: 2rem 1rem 0 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const StyledH2 = styled(InterSemiBold)`
  color: #282828;
  font-size: 48px;
`;

const StyledH3 = styled(InterSemiBold)`
  color: ${theme.gray};
  font-size: 15px;
`;

const InfoTextContainer = styled.div`
  padding: 10px;
`;

const InfoTextContainer2 = styled.div`
  width: 100%;
  color: var(--neutral-500, #7d7b7a);
  font-size: 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  padding: 5px;
`;

const PinContainer = styled.div`
  width: 100%;
  padding: 10px;
  padding-left: 2.81rem;
  padding-right: 2.81rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 10px;
`;

const StyledDropdownButton = styled.div<{
  isVisible: boolean;
  isError: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  background-color: ${theme.lightTextGray};
  color: ${theme.disabledGray};
  border: 2px solid
    ${({ isVisible, isError }) =>
      isError ? "red" : isVisible ? "#8c9aea" : theme.lightTextGray};
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: ${"bold"};
`;

export {
  ErrorMessage,
  PinContainer,
  InfoTextContainer,
  InfoTextContainer2,
  StyledH2,
  StyledH3,
  greyH3,
  TitleContainer,
  BackIconContainer,
  TextContainer,
  PageContainer,
  DropdownContainer,
  StyledDropdownButton,
};
